import React, { useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'
import Layout from '../components/layout/layout'
import Gallery from 'react-photo-gallery'
import styles from "../stylesheets/index.module.css"
import Carousel, { Modal, ModalGateway } from "react-images"
import SEO from "../components/seo"

export const query = graphql`
  {
    priority: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "priority"}}) {
      edges {
        node {
          childImageSharp {
            fixed(quality: 100) {
              src
              width
              height
            }
          }
        }
      }
    }
    rest: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, sourceInstanceName: {eq: "rest"}}) {
      edges {
        node {
          childImageSharp {
            fixed(quality: 100) {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`
const cont = {
  backgroundColor: "#000",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative"
};

export default ({ data }) => {

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const CustomImage = ({index, photo, margin, direction, top, left}) => {
    if (direction === "column") {
      cont.position = "absolute";
      cont.left = left;
      cont.top = top;
    }
  
    return(
      <ScrollAnimation duration={2} offset={40} animateIn="fadeIn" animateOnce>
        <div style={{ margin, height: photo.height, width: photo.width, ...cont }}>
            <img alt={photo.title} {...photo} loading="lazy" onClick={(event) => {openLightbox(event, index)}} />
        </div>
      </ScrollAnimation>
    )
  }

  const openLightbox = (event, index) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <CustomImage
        key={key}
        margin={"2px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
      />
    ),
    []
  );

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  
  let photos = [];

  data.priority.edges.map((el) => {
    let temp = {}
    temp.width = el.node.childImageSharp.fixed.width / 100;
    temp.height =el.node.childImageSharp.fixed.height /100;
    temp.src = el.node.childImageSharp.fixed.src;

    let title = temp.src.split("/")[4];
    title = decodeURI(title.split(".")[0]);
    temp.title = title;

    photos.push(temp);
    return null;
  });

  data.rest.edges.map((el) => {
    let temp = {}
    temp.width = el.node.childImageSharp.fixed.width / 100;
    temp.height =el.node.childImageSharp.fixed.height /100;
    temp.src = el.node.childImageSharp.fixed.src;

    let title = temp.src.split("/")[4];
    title = decodeURI(title.split(".")[0]);
    temp.title = title;

    photos.push(temp);
    return null;
  })

  return(
    <Layout pageTitle="Home">
      <SEO title="Home" />
      <div className={styles.projectWrapper}>
        <Gallery photos={photos} renderImage={imageRenderer} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Layout>
  )
}
